import { MATCH_TYPES, TeamStatKeys, GameStatKeys, TeamAllStats } from "@ollie-sports/models";
import express from "express";
import { getDefaultEndDateForTrends, getDefaultStartDateForTrends, getTeamSoccerStatDefs } from "../..";
import { getServerHelpers } from "../../helpers";
import { isDevelopmentEnv } from "../../internal-utils/misc";
import { validateToken } from "../../internal-utils/server-auth";
import { TeamSoccerStatComputations } from "../../soccer-logic/SoccerStatComputations/TeamSoccerStatComputations";
import { ObjectKeys } from "../../utils/object-keys";

type ReturnType = {
  teamIdWithSquad: string;
  stats: TeamAllStats;
};

export async function trends__server__getStatsForTeams(p: {
  teamIdWithSquads: string[];
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
  locale: string;
}): Promise<ReturnType[]> {
  // SERVER_ONLY_TOGGLE

  const teamStatsKeys: string[] = ObjectKeys(TeamStatKeys).map(k => TeamStatKeys[k]);
  const gameStatsKeys: string[] = ObjectKeys(GameStatKeys).map(k => GameStatKeys[k]);

  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();

  const q1 = p.teamIdWithSquads;
  const q2 = p.matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;

  const queryParams = [q1, q2, q3, q4];

  const query = `
  select c.item ->> 'teamIdWithSquad' as team_id_with_squad,
    ${teamStatsKeys
      .map(key => {
        return `
      sum(coalesce(cast(ss.item -> 'teamStats' -> 'ownTeam' ->> '${key}' as numeric), 0))     as total_${key},
      avg(cast(ss.item -> 'teamStats' -> 'ownTeam' ->> '${key}' as numeric))    as avg_${key},`;
      })
      .join("")}
      ${gameStatsKeys
        .map(key => {
          return `
        sum(coalesce(cast(ss.item -> 'gameStats' ->> '${key}' as numeric), 0))     as game_${key},`;
        })
        .join("")}
    count(1)                                                              as total_games

from mirror_soccerstatsnapshot ss,
mirror_calendarentry c
where c.id = ss.item ->> 'calendarEntryId'
and c.item ->> 'teamIdWithSquad' = any ($1::text[])
and c.item ->> 'isExcludedFromAggregateStats' is null
and ss.item ->> 'snapshotType' = 'all'
and c.item ->> 'calendarEntryType' = any ($2::text[])
and c.c_start_date_truncated_ms between $3 and $4
group by c.item ->> 'teamIdWithSquad';
  `;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  const tempResult: ReturnType[] = [];

  r1.rows.forEach(r => {
    let total: Record<string, number> = {};
    let avg: Record<string, number> = {};
    let gameStats: Record<string, number> = {};
    teamStatsKeys.forEach(statKey => {
      const teamStatKey = statKey as TeamStatKeys;

      if (getTeamSoccerStatDefs(p.locale)[teamStatKey].stringFormatHint === "percent") {
        total[statKey] = parseFloat(r[`total_${statKey}`]);
        avg[statKey] = parseFloat(r[`avg_${statKey}`]);
      } else {
        total[statKey] = parseInt(r[`total_${statKey}`]);
        avg[statKey] = parseFloat(r[`avg_${statKey}`]);
      }
    });

    gameStatsKeys.forEach(statKey => {
      gameStats[statKey] = r[`game_${statKey}`];
    });

    const t: ReturnType = {
      stats: { numGames: parseInt(r.total_games) || 0, teamStatAvg: avg, teamStatTotal: total, gameStatTotal: gameStats },
      teamIdWithSquad: r.team_id_with_squad
    };
    tempResult.push(t);
  });

  const result = tempResult.reduce((acc, team) => {
    const teamStats = { ...team.stats };
    teamStatsKeys.forEach(statKey => {
      const teamStatKey = statKey as TeamStatKeys;

      const statComputationObject = TeamSoccerStatComputations[teamStatKey];
      if (
        statComputationObject.statType === "team" &&
        getTeamSoccerStatDefs(p.locale)[teamStatKey].formatCategory === "percent"
      ) {
        if (!!statComputationObject.computeSeasonAverage) {
          const avg = statComputationObject.computeSeasonAverage({
            teamTotalsAndAverages: {
              avg: team.stats.teamStatAvg,
              total: team.stats.teamStatTotal,
              numGames: team.stats.numGames
            }
          });
          if (avg) {
            teamStats.teamStatAvg[teamStatKey as TeamStatKeys] = avg;
            teamStats.teamStatTotal[teamStatKey as TeamStatKeys] = avg;
          }
        } else {
          if (isDevelopmentEnv()) {
            console.warn(`Team Stat of precentage type is missing computeSeasonAverage function: ${teamStatKey}`);
          }
        }
      }
    });
    acc.push({ teamIdWithSquad: team.teamIdWithSquad, stats: teamStats });
    return acc;
  }, [] as ReturnType[]);

  return result;

  // SERVER_ONLY_TOGGLE
}

trends__server__getStatsForTeams.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
