import {
  CALENDAR_ENTRY_TYPES,
  MATCH_TYPES,
  PositionNumStatKeys,
  SoccerPositionNumber,
  TeamId,
  TeamWithSquad
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends } from "../../utils/date-helpers";

export async function trends__server__getMostRecentStatsDateForPlayers(p: {
  playerIds: string[];
}): Promise<Record<string, number>> {
  // SERVER_ONLY_TOGGLE

  const q1 = p.playerIds;
  const queryParams = [q1];

  const query = `
  select a.player_id                                 as player_id,
  max(cast(b.item ->> 'dateOfEventMS' as numeric)) as date
from derived_snapshot_player_shard a,
mirror_soccerstatsnapshot b,
mirror_calendarentry c,
mirror_soccergame sg
where a.snapshot_id = b.id
and b.item ->> 'calendarEntryId' = c.id
and c.id = sg.item ->> 'calendarEntryId'
and player_id = any ($1::text[])
and c.item ->> 'calendarEntryType' = any ('{game, scrimmage}'::text[])
and c.item ->> 'isExcludedFromAggregateStats' is null
and b.item ->> 'snapshotType' = 'all'
and player_stats ->> 'as' is not null
and sg.item ->> 'statMode' <> 'team'
group by a.player_id
order by date desc
  `;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  return r1.rows.reduce((acc, r) => {
    const playerId = r.player_id;
    if (playerId) {
      acc[playerId] = r.date ?? 0;
    }
    return acc;
  }, {});
  // SERVER_ONLY_TOGGLE
}

trends__server__getMostRecentStatsDateForPlayers.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
