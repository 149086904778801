import {
  AccountId,
  CALENDAR_ENTRY_TYPES,
  MATCH_TYPES,
  Org,
  OrgId,
  PlayerId,
  PlayerMvpAndLeaderboardTotals,
  PositionNumStatKeys,
  SoccerFormationKeys,
  SoccerGameLeaderboardTypes,
  SoccerGameLeaderboardTypesCamelCase,
  SoccerPositionNumber,
  SoccerStatSnapshot,
  Team,
  TeamId,
  TeamStatKeys,
  TeamWithSquad,
  TrendsSnapshotTeamStatsType
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { common__generateTeamIdWithSquad } from "../common.api";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends, ObjectKeys } from "../../utils";
import moment from "moment";

export async function trends__server__getTeamStatsFromSnapshot(p: {
  teamIdWithSquad: string;
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
}): Promise<TrendsSnapshotTeamStatsType[]> {
  // SERVER_ONLY_TOGGLE
  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `
select ss.item ->> 'teamStats'             as team_stats,
       ss.item ->> 'bestFitFormationKey'   as formation,
       c.item ->> 'opponentName'           as opponent_name,
       c.c_start_date_truncated_ms         as date,
       sg.item ->> 'statMode'              as stat_mode
from mirror_soccerstatsnapshot ss,
     mirror_soccergame sg,
     mirror_calendarentry c
where sg.id = ss.item ->> 'soccerGameId'
  and c.id = sg.item ->> 'calendarEntryId'
  and c.item ->> 'teamIdWithSquad' = $1
  and c.item ->> 'isExcludedFromAggregateStats' is null
  and c.item ->> 'calendarEntryType' = any ($2::text[])
  and c.c_start_date_truncated_ms between $3 and $4
  and ss.item ->> 'snapshotType' = 'all';
  `;

  const result: TrendsSnapshotTeamStatsType[] = [];

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);
  r1.rows.map(item => {
    result.push({
      ...JSON.parse(item.team_stats),
      ...{
        bestFitFormationKey: item.formation,
        dateMS: parseInt(item.date),
        opponentTeamName: item.opponent_name,
        statMode: item.stat_mode
      }
    });
  });
  return result;
  // SERVER_ONLY_TOGGLE
}

trends__server__getTeamStatsFromSnapshot.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
