import {
  AccountId,
  CALENDAR_ENTRY_TYPES,
  MATCH_TYPES,
  Org,
  OrgId,
  PlayerId,
  PlayerMvpAndLeaderboardTotals,
  PlayerStatKeys,
  PlayerStatsSnapshots,
  PositionNumStatKeys,
  SnapshotTeamStatsType,
  SoccerGameLeaderboardTypes,
  SoccerGameLeaderboardTypesCamelCase,
  SoccerPositionNumber,
  SoccerStatSnapshot,
  Team,
  TeamId,
  TeamWithSquad
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { common__generateTeamIdWithSquad } from "../common.api";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends, ObjectKeys } from "../../utils";
import moment from "moment";

export async function trends__server__getPlayerStatsFromSnapshot(p: {
  teamIdsWithSquad: string[];
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
  playerIds: PlayerId[];
}): Promise<PlayerStatsSnapshots[]> {
  // SERVER_ONLY_TOGGLE

  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.playerIds;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];
  if (p.teamIdsWithSquad.length) {
    const q5 = p.teamIdsWithSquad;
    queryParams.push(q5);
  }

  const query = `
select a.player_id                                 as player_id,
       player_stats                                as player_stats,
       c.c_start_date_truncated_ms                 as date,
       c.item -> 'opponentName'                    as opponent_name
from derived_snapshot_player_shard a,
     mirror_soccerstatsnapshot b,
     mirror_calendarentry c,
     mirror_soccergame sg
where a.snapshot_id = b.id
  and b.item ->> 'calendarEntryId' = c.id
  and c.id = sg.item ->> 'calendarEntryId'
  and player_id = any ($1::text[])
  and c.item ->> 'calendarEntryType' = any ($2::text[])
  and c.c_start_date_truncated_ms between $3 and $4
  and c.item ->> 'isExcludedFromAggregateStats' is null
  and b.item ->> 'snapshotType' = 'all'
  and player_stats ->> 'as' is not null
  and sg.item ->> 'statMode' <> 'team'
  ${p.teamIdsWithSquad.length ? `and c.item ->> 'teamIdWithSquad' = any ($5::text[])` : ""}
order by date desc;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  const results: {
    playerId: PlayerId;
    playerStats: { [key in PlayerStatKeys]?: number };
    dateOfGame: number;
    opponentName: string;
  }[] = [];

  r1.rows.map(r => {
    results.push({
      playerId: r.player_id,
      playerStats: r.player_stats,
      dateOfGame: parseInt(r.date),
      opponentName: r.opponent_name
    });
  });

  return results;
  // SERVER_ONLY_TOGGLE
}

trends__server__getPlayerStatsFromSnapshot.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
