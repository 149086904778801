import { CALENDAR_ENTRY_TYPES, MATCH_TYPES, TeamId, TeamWithSquad } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import moment from "moment";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends } from "../../utils/date-helpers";

export async function trends__server__getGameResultTotals(p: {
  teamIdWithSquad: string;
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
}): Promise<Record<"win" | "loss" | "tie", number>> {
  // SERVER_ONLY_TOGGLE
  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `
select sum(case when a.result = 'win' then 1 else 0 end)  as win,
  sum(case when a.result = 'loss' then 1 else 0 end) as loss,
  sum(case when a.result = 'tie' then 1 else 0 end)  as tie
from (
    select sg.item ->> 'gameResult' as result,
           sg.item -> 'teamId'      as teamId
    from mirror_soccerstatsnapshot sg,
         mirror_calendarentry c
    where c.id = sg.item ->> 'calendarEntryId'
      and c.item ->> 'teamIdWithSquad' = $1
      and c.item ->> 'isExcludedFromAggregateStats' is null
      and sg.item ->> 'snapshotType' = 'all'
      and c.item ->> 'calendarEntryType' = any ($2::text[])
      and c.c_start_date_truncated_ms between $3 and $4
) a
  `;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  return {
    win: parseInt(r1.rowCount > 0 ? r1.rows[0].win ?? 0 : 0),
    loss: parseInt(r1.rowCount > 0 ? r1.rows[0].loss ?? 0 : 0),
    tie: parseInt(r1.rowCount > 0 ? r1.rows[0].tie ?? 0 : 0)
  };
  // SERVER_ONLY_TOGGLE
}

trends__server__getGameResultTotals.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
