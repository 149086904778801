import {
  CALENDAR_ENTRY_TYPES,
  MATCH_TYPES,
  PositionNumStatKeys,
  SoccerPositionNumber,
  TeamId,
  TeamWithSquad
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends } from "../../utils/date-helpers";

export async function trends__server__getHeatMapTotals(p: {
  teamIdWithSquad: string;
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
}): Promise<Record<PositionNumStatKeys, { [str in SoccerPositionNumber]?: number }>> {
  // SERVER_ONLY_TOGGLE
  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `
select c.key,
  c.position_number,
  sum(cast(c.stat_number as numeric))
from (
    select b.key,
           (b.values).key   as position_number,
           (b.values).value as stat_number
    from (select (a.pns).key               as key,
                 jsonb_each((a.pns).value) as values
          from (select jsonb_each(sg.item -> 'positionNumberStats') as pns
                from mirror_soccerstatsnapshot sg,
                     mirror_calendarentry c
                where c.id = sg.item ->> 'calendarEntryId'
                  and c.item ->> 'teamIdWithSquad' = $1
                  and c.item ->> 'isExcludedFromAggregateStats' is null
                  and sg.item ->> 'snapshotType' = 'all'
                  and c.item ->> 'calendarEntryType' = any ($2::text[])
                  and c.c_start_date_truncated_ms between $3 and $4) a) b) c
group by c.key, c.position_number;
  `;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);
  const result = r1.rowCount
    ? r1.rows.reduce((acc, val) => {
        const test = acc[val.key];
        if (test) {
          acc[val.key][val.position_number] = parseInt(val.sum);
        } else {
          acc[val.key] = { [val.position_number]: parseInt(val.sum) };
        }
        return acc;
      }, {} as any)
    : undefined;

  return result;
  // SERVER_ONLY_TOGGLE
}

trends__server__getHeatMapTotals.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
