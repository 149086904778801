import {
  CALENDAR_ENTRY_TYPES,
  MATCH_TYPES,
  PositionNumStatKeys,
  SoccerPositionNumber,
  TeamId,
  TeamWithSquad
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends } from "../../utils/date-helpers";

export async function trends__server__getMostRecentStatsDateForTeamIdsWithSquad(p: {
  teamIdsWithSquad: string[];
}): Promise<Record<string, number>> {
  // SERVER_ONLY_TOGGLE

  const q1 = p.teamIdsWithSquad;
  const queryParams = [q1];

  const query = `
  select c.item ->> 'teamIdWithSquad' as team_id_with_squad,
       max(sg.item ->> 'officialStartOfGameMS') as date
from mirror_soccerstatsnapshot ss,
     mirror_soccergame sg,
     mirror_calendarentry c
where sg.id = ss.item ->> 'soccerGameId'
  and c.id = sg.item ->> 'calendarEntryId'
  and c.item ->> 'teamIdWithSquad' = any ($1::text[])
  and c.item ->> 'isExcludedFromAggregateStats' is null
  and c.item ->> 'calendarEntryType' = any ('{game, scrimmage}'::text[])
  and ss.item ->> 'snapshotType' = 'all'
group by c.item->>'teamIdWithSquad'
order by date desc;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  return r1.rows.reduce((acc, r) => {
    const teamIdWithSquad = r.team_id_with_squad;
    if (teamIdWithSquad) {
      acc[teamIdWithSquad] = r.date ?? 0;
    }
    return acc;
  }, {});
  // SERVER_ONLY_TOGGLE
}

trends__server__getMostRecentStatsDateForTeamIdsWithSquad.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
