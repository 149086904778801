import { CALENDAR_ENTRY_TYPES, MATCH_TYPES, TeamId, TeamWithSquad } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import moment from "moment";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends } from "../../utils/date-helpers";

export async function trends__server__getNumberOfGamesWithPlayerFilters(p: {
  playerIds: string[];
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
  teamIdsWithSquad: string[];
}): Promise<number> {
  // SERVER_ONLY_TOGGLE
  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.playerIds;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  let queryParams = [q1, q2, q3, q4];

  if (p.teamIdsWithSquad.length) {
    const q5 = p.teamIdsWithSquad;
    queryParams.push(q5);
  }

  const query = `select count(*) as num_games
  from derived_snapshot_player_shard a,
       mirror_soccerstatsnapshot b,
       mirror_calendarentry c,
       mirror_soccergame sg
  where a.snapshot_id = b.id
    and b.item ->> 'calendarEntryId' = c.id
    and c.id = sg.item ->> 'calendarEntryId'
    ${p.teamIdsWithSquad.length ? `and c.item ->> 'teamIdWithSquad' = any ($5::text[])` : ""}
    and player_id = any ($1::text[])
    and c.item ->> 'calendarEntryType' = any ($2::text[])
    and c.item ->> 'isExcludedFromAggregateStats' is null
    and b.item ->> 'snapshotType' = 'all'
    and c.c_start_date_truncated_ms between $3 and $4
    and player_stats ->> 'as' is not null
    and sg.item ->> 'statMode' <> 'team';`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  return r1.rowCount > 0 ? r1.rows[0].num_games ?? 0 : 0;
  // SERVER_ONLY_TOGGLE
}

trends__server__getNumberOfGamesWithPlayerFilters.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
