import {
  AccountId,
  CALENDAR_ENTRY_TYPES,
  MATCH_TYPES,
  Org,
  OrgId,
  PlayerId,
  PlayerMvpAndLeaderboardTotals,
  PositionNumStatKeys,
  SnapshotTeamStatsType,
  SoccerGameLeaderboardTypes,
  SoccerGameLeaderboardTypesCamelCase,
  SoccerPositionNumber,
  SoccerStatSnapshot,
  Team,
  TeamId,
  TeamStatKeys,
  TeamTotalsAndAverages,
  TeamWithSquad
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { common__generateTeamIdWithSquad } from "../common.api";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends, ObjectKeys } from "../../utils";
import moment from "moment";
import { getTeamSoccerStatDefs } from "../../soccer-logic";
import { TeamSoccerStatComputations } from "../../soccer-logic/SoccerStatComputations/TeamSoccerStatComputations";
import { isDevelopmentEnv } from "../../internal-utils/misc";

export async function trends__server__getTeamTotalsAndAverages(p: {
  teamIdWithSquad: string;
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
  locale: string;
  // }): Promise<Record<"ownTeam" | "opponentTeam", any>> {
}): Promise<TeamTotalsAndAverages> {
  // SERVER_ONLY_TOGGLE

  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const allTeamStatsKeys: string[] = ObjectKeys(TeamStatKeys).map(k => TeamStatKeys[k]);
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `
select c.team                                                                                          as team,
       sum(case when c.stat_key = 'tt' then 1 else 0 end)                                              as num_games,
       ${allTeamStatsKeys
         .map(key => {
           return `
      coalesce(sum(case when c.stat_key = '${key}' then cast(c.stat_value #>> '{}' as numeric) else 0 end), 0)     as total_${key},
      avg(case when c.stat_key = '${key}' then cast(c.stat_value #>> '{}' as numeric) else null end)  as avg_${key}`;
         })
         .join(",")}
from (select b.team, (b.stats).key as stat_key, (b.stats).value as stat_value
      from (select (a.team_stats).key as team, jsonb_each((a.team_stats).value) as stats
            from (select jsonb_each(sg.item -> 'teamStats') as team_stats
                  from mirror_soccerstatsnapshot sg,
                       mirror_calendarentry c
                  where c.id = sg.item ->> 'calendarEntryId'
                    and c.item ->> 'teamIdWithSquad' = $1
                    and c.item ->> 'isExcludedFromAggregateStats' is null
                    and sg.item ->> 'snapshotType' = 'all'
                    and c.item ->> 'calendarEntryType' = any ($2::text[])
                    and c.c_start_date_truncated_ms between $3 and $4
                  group by sg.id
                 ) a) b) c
group by c.team;
`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  const tempResult: TeamTotalsAndAverages = {
    ownTeam: { numGames: 0, avg: {}, total: {} },
    opponentTeam: { numGames: 0, avg: {}, total: {} }
  };

  r1.rows.forEach((r, index) => {
    const team: "ownTeam" | "opponentTeam" = r.team as "ownTeam" | "opponentTeam";
    const numGames = parseInt(r.num_games);
    let total: Record<string, number> = {};
    let avg: Record<string, number> = {};
    allTeamStatsKeys.forEach(statKey => {
      const teamStatKey = statKey as TeamStatKeys;

      if (getTeamSoccerStatDefs(p.locale)[teamStatKey].stringFormatHint === "percent") {
        total[statKey] = parseFloat(r[`total_${statKey}`]);
        avg[statKey] = parseFloat(r[`avg_${statKey}`]);
      } else {
        total[statKey] = parseInt(r[`total_${statKey}`]);
        avg[statKey] = parseFloat(r[`avg_${statKey}`]);
      }
    });
    tempResult[team] = {
      avg,
      total,
      numGames
    };
  });

  const result = { ...tempResult };

  ["ownTeam", "opponentTeam"].map(team => {
    allTeamStatsKeys.forEach(statKey => {
      const teamStatKey = statKey as TeamStatKeys;

      const statComputationObject = TeamSoccerStatComputations[teamStatKey];
      if (
        statComputationObject.statType === "team" &&
        getTeamSoccerStatDefs(p.locale)[teamStatKey].formatCategory === "percent"
      ) {
        if (!!statComputationObject.computeSeasonAverage) {
          const avg = statComputationObject.computeSeasonAverage({
            teamTotalsAndAverages: tempResult[team as "ownTeam" | "opponentTeam"]
          });
          if (avg) {
            result[team as "ownTeam" | "opponentTeam"].avg[teamStatKey as TeamStatKeys] = avg;
            result[team as "ownTeam" | "opponentTeam"].total[teamStatKey as TeamStatKeys] = avg;
          }
        } else {
          if (isDevelopmentEnv()) {
            console.warn(`Team Stat of precentage type is missing computeSeasonAverage function: ${teamStatKey}`);
          }
        }
      }
    });
  });

  return result;
  // SERVER_ONLY_TOGGLE
}

trends__server__getTeamTotalsAndAverages.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
