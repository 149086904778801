import {
  AccountId,
  MATCH_TYPES,
  MvpTypes,
  Org,
  OrgId,
  PlayerId,
  PlayerMvpAndLeaderboardTotals,
  PlayerStatKeys,
  PlayerTotalsAndAveragesReturnTypeForTeam,
  PositionNumStatKeys,
  SnapshotTeamStatsType,
  SoccerGameLeaderboardTypes,
  SoccerGameLeaderboardTypesCamelCase,
  SoccerStatSnapshot,
  Team,
  TeamId,
  TeamStatKeys,
  TeamWithSquad
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { common__generateTeamIdWithSquad } from "../common.api";
import {
  getDefaultEndDateForTrends,
  getDefaultGameTypesForTrends,
  getDefaultStartDateForTrends,
  ObjectKeys,
  ObjectValues
} from "../../utils";
import moment from "moment";
import { getPlayerSoccerStatDefs } from "../../soccer-logic";
import { PlayerSoccerStatComputations } from "../../soccer-logic/SoccerStatComputations/PlayerSoccerStatComputations";
import { isDevelopmentEnv } from "../../internal-utils/misc";

export async function trends__server__getPlayerTotalsAndAveragesForTeam(p: {
  teamIdWithSquad: string;
  matchTypes?: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
  locale: string;
}): Promise<PlayerTotalsAndAveragesReturnTypeForTeam[]> {
  // SERVER_ONLY_TOGGLE
  const allPlayerStatKeys: string[] = ObjectKeys(PlayerStatKeys).map(k => PlayerStatKeys[k]);

  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes?.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `select
  a.player_id as player_id,
  count(*) as num_games,
  sum(coalesce(cast(timespentatposition->>'goalkeeper' as numeric), 0)) as time_as_goalkeeper,
  sum(coalesce(cast(timespentatposition->>'defender' as numeric), 0)) as time_as_defender,
  sum(coalesce(cast(timespentatposition->>'midfielder' as numeric), 0)) as time_as_midfielder,
  sum(coalesce(cast(timespentatposition->>'forward' as numeric), 0)) as time_as_forward,
  ${allPlayerStatKeys
    .map(statKey => {
      return `sum(coalesce(cast(a.player_stats ->> '${statKey}' as numeric), 0)) as stat_total_${statKey}, avg(cast(a.player_stats ->> '${statKey}' as numeric)) as stat_avg_${statKey}`;
    })
    .join(",")},
  sum(coalesce(cast(dsp.award_mvp_nodes -> 'mvpWinners' ->> 'mvp' as numeric), 0))             as mvp_overall,
  sum(coalesce(cast(dsp.award_mvp_nodes -> 'mvpWinners' ->> 'bestOffense' as numeric), 0))             as mvp_offense,
  sum(coalesce(cast(dsp.award_mvp_nodes -> 'mvpWinners' ->> 'bestDefense' as numeric), 0))             as mvp_defense,
  ${ObjectValues(SoccerGameLeaderboardTypes)
    .map(leaderboardType => {
      return `sum(coalesce(cast(dsp.award_mvp_nodes -> 'leaderboardWinners' ->> '${leaderboardType}' as numeric), 0)) as award_${SoccerGameLeaderboardTypesCamelCase[leaderboardType]}`;
    })
    .join(",")}
from (
    select dsn.*
    from mirror_calendarentry c,
         derived_snapshot_player_shard dsn
    where c.id = dsn.calendar_entry_id
      and c.item ->> 'teamIdWithSquad' = $1
      and c.item ->> 'isExcludedFromAggregateStats' is null
      and c.item ->> 'calendarEntryType' = any ($2::text[])
      and c.c_start_date_truncated_ms between $3 and $4
) a
    left join derived_soccergame_awards_mvp_by_player_shards dsp on a.calendar_entry_id = dsp.calendar_entry_id
and a.player_id = dsp.player_id
group by a.player_id;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  const result: PlayerTotalsAndAveragesReturnTypeForTeam[] = [];

  r1.rows.map(r => {
    let total: Record<string, number> = {};
    let avg: Record<string, number> = {};

    allPlayerStatKeys.forEach(statKey => {
      const playerStatKey = statKey as PlayerStatKeys;

      if (getPlayerSoccerStatDefs(p.locale)[playerStatKey].stringFormatHint === "percent") {
        total[statKey] = parseFloat(r[`stat_total_${statKey}`]);
        avg[statKey] = parseFloat(r[`stat_avg_${statKey}`]);
      } else {
        total[statKey] = parseInt(r[`stat_total_${statKey}`]);
        avg[statKey] = parseFloat(r[`stat_avg_${statKey}`]);
      }
    });

    const tempReturnVal: PlayerTotalsAndAveragesReturnTypeForTeam = {
      playerId: r.player_id,
      numGames: parseInt(r.num_games),
      mvps: {
        bestDefense: parseInt(r.mvp_defense),
        bestOffense: parseInt(r.mvp_offense),
        mvp: parseInt(r.mvp_overall)
      },
      awards: ObjectKeys(SoccerGameLeaderboardTypesCamelCase).reduce((acc, leaderBoardType) => {
        acc[leaderBoardType] = parseInt(r[`award_${SoccerGameLeaderboardTypesCamelCase[leaderBoardType]}`]);
        return acc;
      }, {} as { [type in SoccerGameLeaderboardTypes]: number }),
      avg,
      total,
      timeSpentAtPosition: {
        defender: parseInt(r.time_as_defender),
        midfielder: parseInt(r.time_as_midfielder),
        forward: parseInt(r.time_as_forward),
        goalkeeper: parseInt(r.time_as_goalkeeper)
      }
    };

    const returnVal = { ...tempReturnVal };

    // Compute the percentage stats

    allPlayerStatKeys.forEach(statKey => {
      const playerStatKey = statKey as PlayerStatKeys;

      if (getPlayerSoccerStatDefs(p.locale)[playerStatKey].formatCategory === "percent") {
        const statComputationObject = PlayerSoccerStatComputations[playerStatKey];
        if (
          statComputationObject.statType === "player" &&
          getPlayerSoccerStatDefs(p.locale)[playerStatKey].formatCategory === "percent"
        ) {
          if (!!statComputationObject.computeSeasonAverage) {
            const avg = statComputationObject.computeSeasonAverage({
              playerTotalsAndAverages: tempReturnVal
            });
            if (avg) {
              returnVal.avg[statKey as PlayerStatKeys] = avg;
              returnVal.total[statKey as PlayerStatKeys] = avg;
            }
          } else {
            if (isDevelopmentEnv()) {
              console.warn(`Player Stat of precentage type is missing computeSeasonAverage function: ${playerStatKey}`);
            }
          }
        }
      }
    });

    result.push(returnVal);
  });

  return result;
  // SERVER_ONLY_TOGGLE
}

trends__server__getPlayerTotalsAndAveragesForTeam.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
