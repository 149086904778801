import { MATCH_TYPES } from "@ollie-sports/models";
import { getDefaultEndDateForTrends, getDefaultStartDateForTrends } from "../..";
import { getServerHelpers } from "../../helpers";

export * from "./postgres-stat-queries";
export * from "./trends__server__getTeamsWithSquadOptions";
export * from "./trends__getPlayerTotalsAndAverages";
export * from "./trends__getHeatMapTotals";
export * from "./trends__getGameResultTotals";
export * from "./trends__getTeamStatsFromSnapshot";
export * from "./trends__getPlayerStatsFromSnapshot";
export * from "./trends__getTeamTotalsAndAverages";
export * from "./trends__getStatsForTeams";
export * from "./trends__getPlayerTotalsAndAveragesForTeam";
export * from "./trends__getMostRecentStatsDateForPlayerIds";
export * from "./trends__getMostRecentStatsDateForTeamIdsWithSquad";
export * from "./trends__getNumberOfGamesWithTeamFilters";
export * from "./trends__getNumberOfGamesWithPlayerFilters";
export * from "./trends__getShotEventsForTeam";
export * from "./trends__getShotEventsForPlayer";

export async function trends__server_teamPage1(p: {
  teamIdWithSquad: string;
  startDateMS: number;
  endDateMS: number;
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
}) {
  // SERVER_ONLY_TOGGLE
  const pool = getServerHelpers().getAppPgPool();

  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = p.matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `
  select c.c_start_date_truncated_ms                                           as date_of_event_ms,
       sg.item                                                               as snapshot,
       c.item ->> 'opponentName'                                             as opp_name,
       c.item -> 'derived' -> 'endGameDetails' -> 'score' ->> 'ownTeam'      as own_team_score,
       c.item -> 'derived' -> 'endGameDetails' -> 'score' ->> 'opponentTeam' as opp_team_score,
       c.item -> 'derived' -> 'endGameDetails' ->> 'gameResult'              as game_result
from mirror_soccerstatsnapshot sg,
     mirror_calendarentry c
where c.id = sg.item ->> 'calendarEntryId'
  and c.id = b.item ->> 'calendarEntryId'
  and c.item ->> 'teamIdWithSquad' = $1
  and c.item ->> 'calendarEntryType' = any ($2::text[])
  and c.item ->> 'isExcludedFromAggregateStats' is null
  and sg.item ->> 'snapshotType' = 'all'
  and c.c_start_date_truncated_ms between $3 and $4
order by c.c_start_date_truncated_ms desc
limit 20;
  `;

  const r1 = await pool.query(query, queryParams);

  return r1.rows;
  // SERVER_ONLY_TOGGLE
}

trends__server_teamPage1.auth = () => {};

// i18n certified - complete
