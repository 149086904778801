import { CALENDAR_ENTRY_TYPES, MATCH_TYPES, SoccerGameEvent, TeamId, TeamWithSquad } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import moment from "moment";
import { getDefaultEndDateForTrends, getDefaultGameTypesForTrends, getDefaultStartDateForTrends } from "../../utils/date-helpers";
import { isShotEvent, SoccerShotEvent } from "../../soccer-logic";

export async function trends__server__getShotEventsForTeam(p: {
  teamIdWithSquad: string;
  matchTypes: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
}): Promise<SoccerShotEvent[]> {
  // SERVER_ONLY_TOGGLE
  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const q1 = p.teamIdWithSquad;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];

  const query = `select event
  from derived_soccer_game_event_bundle_key_events sge,
       mirror_calendarentry c
  where c.id = sge.calendar_entry_id
    and c.item ->> 'teamIdWithSquad' = $1
    and c.item ->> 'isExcludedFromAggregateStats' is null
    and c.item ->> 'calendarEntryType' = any ($2::text[])
    and c.c_start_date_truncated_ms between $3 and $4;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  return r1.rows.map(r => r.event as SoccerGameEvent).filter(event => isShotEvent(event)) as SoccerShotEvent[];
  // SERVER_ONLY_TOGGLE
}

trends__server__getShotEventsForTeam.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
