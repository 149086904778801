import { AccountId, Org, OrgId, PlayerId, Team, TeamId, TeamWithSquad } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { common__generateTeamIdWithSquad } from "../common.api";
import { ObjectKeys } from "../../utils";
import { compute } from "../..";

export async function trends__server__getTeamsWithSquadOptions(p: { accountId: AccountId }): Promise<TeamWithSquad[]> {
  // SERVER_ONLY_TOGGLE
  const query = `
select t2.item           as team,
  o.item ->> 'name' as org_name
from (select distinct c.id as team_id
 from (select id
       from mirror_team
       where item -> 'accounts' -> $1 is not null
         and c_deleted_at_ms = 0
       union
       select t.id
       from (select id as org_id
             from mirror_org
             where item -> 'accounts' -> $1 is not null) a,
            mirror_team t
       where t.item ->> 'orgId' = a.org_id
         and t.c_deleted_at_ms = 0) c) d,
mirror_team t2
    left join mirror_org o on o.id = t2.item ->> 'orgId'
where d.team_id = t2.id;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, [p.accountId]);

  const results: TeamWithSquad[] = [];

  r1.rows.forEach(row => {
    const team: Team = row.team;
    if (team.enabledFeatures?.squads) {
      results.push({
        ...team,
        teamIdWithSquad: common__generateTeamIdWithSquad(team.id),
        teamNameWithSquad: `${team.name} (No Squad)`
      });
      compute.team.teamExtractSquadKeys(team.squads).forEach(squad => {
        results.push({
          ...team,
          teamIdWithSquad: common__generateTeamIdWithSquad(team.id, squad),
          squad: squad,
          teamNameWithSquad: `${team.name} (${getSquadPrettyName(squad)})`
        });
      });
    } else {
      results.push({
        ...team,
        teamIdWithSquad: common__generateTeamIdWithSquad(team.id),
        teamNameWithSquad: team.name
      });
    }
  });

  return results;
  // SERVER_ONLY_TOGGLE
}

trends__server__getTeamsWithSquadOptions.auth = async (r: express.Request) => {
  // await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

function getSquadPrettyName(squad: "a" | "b" | "c") {
  if (squad === "a") return "Varsity";
  if (squad === "b") return "JV";
  if (squad === "c") return "Fresh/Soph";

  return "";
}

// i18n certified - complete
