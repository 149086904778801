import { GameStatKeys, TeamStatKeys } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import moment from "moment-timezone";
import { validateToken } from "../../internal-utils/server-auth";
import { ObjectKeys } from "../../utils";
import * as express from "express";
import _ from "lodash";

interface ReturnUnit<T> {
  dateStrAtStartOfChunkWithTZ: string; //Example: 2020-12-01 00:00:00.000000
  stats: {
    avg: { [P in keyof T]: number };
    min: { [P in keyof T]: number };
    max: { [P in keyof T]: number };
    stddev: { [P in keyof T]: number };
    sum: { [P in keyof T]: number };
  };
  eventCount: number;
}

interface TrendLineReturnUnit<T> {
  dateStrAtStartOfChunkWithTZ: string; //Example: 2020-12-01 00:00:00.000000
  stats: {
    avg: { [P in keyof T]: number };
    sum: { [P in keyof T]: number };
  };
  eventCount: number;
}

interface OverallAvgReturnUnit<T> {
  stats: {
    avg: { [P in keyof T]: number };
    sum: { [P in keyof T]: number };
  };
  eventCount: number;
}

interface TeamAggReturn<T> {
  timeToExecuteMS: number;
  timezone: string;
  data: ReturnUnit<T>[];
  overallAvgs: OverallAvgReturnUnit<T>;
  trendLines: {
    start: TrendLineReturnUnit<T>;
    stop: TrendLineReturnUnit<T>;
  };
}

// TODO Handle games marked as practice
// TODO Handle SQL injection
export async function getTeamAggregateStats<T extends { [key in TeamStatKeys | GameStatKeys]?: true }>(p: {
  teamIdsWithSquad: string[];
  startDateFilterMS: number;
  endDateFilterMS: number;
  // eventType: "game" | "scrimmage" | "both"; // TODO
  // snapshotType: "all" | "first-half" | "second-half-plus"; // TODO
  chunk: "week" | "month";
  startOfWeek: "sunday" | "monday";
  stats: T;
  timezone: string;
}): Promise<TeamAggReturn<T>> {
  // SERVER_ONLY_TOGGLE
  const rawStatKeys = ObjectKeys(p.stats);

  const emptyStatsNode = {
    avg: rawStatKeys.reduce((acc: any, k) => {
      acc[k] = 0;
      return acc;
    }, {}),
    sum: rawStatKeys.reduce((acc: any, k) => {
      acc[k] = 0;
      return acc;
    }, {})
  };

  const emptyObj = {
    overallAvgs: { eventCount: 0, stats: emptyStatsNode },
    data: [],
    timeToExecuteMS: 0,
    timezone: p.timezone,
    trendLines: {
      start: {
        dateStrAtStartOfChunkWithTZ: "",
        eventCount: 0,
        stats: emptyStatsNode
      },
      stop: {
        dateStrAtStartOfChunkWithTZ: "",
        eventCount: 0,
        stats: emptyStatsNode
      }
    }
  };

  if (!p.teamIdsWithSquad.length) {
    return emptyObj;
  }

  const startMS = Date.now();

  const teamStatKeys: string[] = [];
  const gameStatKeys: string[] = [];

  if (rawStatKeys.length === 0) {
    throw new Error("At least one stat must be specified to run getTeamAggregateStats");
  }

  const allTeamStatsKeys: string[] = ObjectKeys(TeamStatKeys).map(k => TeamStatKeys[k]);
  const allGameStatKeys: string[] = ObjectKeys(GameStatKeys).map(k => GameStatKeys[k]);

  // Need to break team and game stat keys apart since will be handled slightly differently in the query
  rawStatKeys.forEach(k => {
    if (allTeamStatsKeys.includes(k)) {
      teamStatKeys.push(k);
    } else if (allGameStatKeys.includes(k)) {
      gameStatKeys.push(k);
    }
  });

  // Make sure start date is before end date
  if (p.startDateFilterMS > p.endDateFilterMS) {
    throw new Error("Invalid start/end dates. Start date must come after end date in filter");
  }

  // Adjust moment locale so first day or week is consistent
  // doy shouldn't matter since we never get first day of the year
  // https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
  moment.updateLocale("en", { week: { dow: p.startOfWeek === "sunday" ? 0 : 1, doy: 4 } });
  // Adjust start times to go to start/end of periods
  const adjustedStartDateFilterMS = moment(p.startDateFilterMS).tz(p.timezone).startOf(p.chunk).valueOf() + 1000;
  const adjustedEndDateFilterMS = moment(p.endDateFilterMS).tz(p.timezone).endOf(p.chunk).valueOf() - 1000;

  // Adjust start of week for postgres as well. Note we use a custom postgres function
  const startOfWeek = p.startOfWeek === "monday" ? "monday" : "sunday";

  const query = `
  select d.date_str,
${[...teamStatKeys, ...gameStatKeys]
  .map(k => {
    return `COALESCE(c.avg_${k}, 0) avg_${k},
    COALESCE(c.min_${k}, 0) min_${k},
  COALESCE(c.max_${k}, 0) max_${k},
  COALESCE(c.stddev_${k}, 0) stddev_${k},
  COALESCE(c.sum_${k}, 0) sum_${k},`;
  })
  .join("\n")}
  COALESCE(c.total_events, 0) total_events
from (select b.date_of_event_trunk,
${[...teamStatKeys, ...gameStatKeys]
  .map(k => {
    return `avg(b.${k}) as avg_${k},
    max(b.${k}) as max_${k},
    sum(b.${k}) as sum_${k},
    stddev(b.${k}) as stddev_${k},
    min(b.${k}) as min_${k},`;
  })
  .join("\n")}
        count(*)      total_events
 from (select date_trunc_enhanced('${p.chunk}', '${startOfWeek}', to_timestamp(a.date_of_event) at time zone '${
    p.timezone
  }') date_of_event_trunk, a.*
       from (select round(cast(item->>'dateOfEventMS' as numeric) / 1000)   date_of_event,
${[
  ...teamStatKeys.map(k => {
    return `cast(item->'teamStats'->'ownTeam'->>'${k}' as numeric) ${k}`;
  }),
  ...gameStatKeys.map(k => {
    return `cast(item->'gameStats'->>'${k}' as numeric) ${k}`;
  })
].join("\n,")}
             from mirror_soccerstatsnapshot
             where item->>'teamIdWithSquad' in(${p.teamIdsWithSquad.map(t => `'${t}'`).join(",")})
               and item->>'snapshotType' = 'all'
               and cast(item->>'dateOfEventMS' as numeric) between ${adjustedStartDateFilterMS} and ${adjustedEndDateFilterMS}) a) b
 group by b.date_of_event_trunk) c
  right join (SELECT date_trunc_enhanced('${p.chunk}', '${startOfWeek}', generate_series) as date_str
              FROM generate_series(to_timestamp(round(cast(${adjustedStartDateFilterMS} as numeric) / 1000)) at time zone '${
    p.timezone
  }',
                                   to_timestamp(round(cast(${adjustedEndDateFilterMS} as numeric) / 1000)) at time zone '${
    p.timezone
  }',
                                   '1 ${p.chunk}')) d on c.date_of_event_trunk = d.date_str order by  d.date_str asc;

`;

  const q1 = await getServerHelpers().getAppPgPool().query(query);

  const returnRows: TeamAggReturn<T>["data"] = q1.rows.map(row => {
    // We need this to be serializable, removing any weird stuff postgres adds on
    row = JSON.parse(JSON.stringify(row));
    const result: TeamAggReturn<T>["data"][number] = {
      dateStrAtStartOfChunkWithTZ: row.date_str,
      eventCount: parseInt(row.total_events),
      stats: {
        avg: rawStatKeys.reduce((acc: any, k) => {
          acc[k] = parseFloat(row[`avg_${k}`]);
          return acc;
        }, {}),
        min: rawStatKeys.reduce((acc: any, k) => {
          acc[k] = parseFloat(row[`min_${k}`]);
          return acc;
        }, {}),
        max: rawStatKeys.reduce((acc: any, k) => {
          acc[k] = parseFloat(row[`max_${k}`]);
          return acc;
        }, {}),
        sum: rawStatKeys.reduce((acc: any, k) => {
          acc[k] = parseFloat(row[`sum_${k}`]);
          return acc;
        }, {}),
        stddev: rawStatKeys.reduce((acc: any, k) => {
          acc[k] = parseFloat(row[`stddev_${k}`]);
          return acc;
        }, {})
      }
    };
    return result;
  });

  const trendsRawData = [...returnRows];
  let firstHalfData: ReturnUnit<T>[] = [];
  let secondHalfData: ReturnUnit<T>[] = [];
  let middleUnit: ReturnUnit<T> | undefined;
  if (returnRows.length % 2 === 0) {
    firstHalfData = trendsRawData.splice(0, returnRows.length / 2);
    secondHalfData = trendsRawData.splice(0);
  } else {
    firstHalfData = trendsRawData.splice(0, Math.floor(returnRows.length / 2));
    middleUnit = trendsRawData.shift();
    secondHalfData = trendsRawData.splice(0);
  }

  function findOverallAvgOfStats(props: { dataUnits: ReturnUnit<T>[] }): OverallAvgReturnUnit<T> {
    const eventCount = props.dataUnits.reduce((acc, du) => acc + du.eventCount, 0);
    const sums = rawStatKeys.reduce((acc, k) => {
      acc[k] = props.dataUnits.reduce((a, b) => b.stats.sum[k] + a, 0 as number);
      return acc;
    }, {} as { [P in keyof T]: number });

    return {
      eventCount,
      stats: {
        avg: _.mapValues(sums, a => a / eventCount),
        sum: sums
      }
    };
  }

  const trendLines: TeamAggReturn<T>["trendLines"] = {
    start: {
      dateStrAtStartOfChunkWithTZ: returnRows[0].dateStrAtStartOfChunkWithTZ,
      eventCount: firstHalfData.reduce((a, b) => a + b.eventCount, 0),
      stats: findOverallAvgOfStats({ dataUnits: firstHalfData }).stats
    },
    stop: {
      dateStrAtStartOfChunkWithTZ: returnRows[returnRows.length - 1].dateStrAtStartOfChunkWithTZ,
      eventCount: secondHalfData.reduce((a, b) => a + b.eventCount, 0),
      stats: findOverallAvgOfStats({ dataUnits: secondHalfData }).stats
    }
  };

  let r: TeamAggReturn<T> = {
    timeToExecuteMS: Date.now() - startMS,
    timezone: p.timezone,
    data: returnRows,
    trendLines,
    overallAvgs: findOverallAvgOfStats({ dataUnits: returnRows })
  };

  return r;
  // SERVER_ONLY_TOGGLE
}

getTeamAggregateStats.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
