import {
  MATCH_TYPES,
  PlayerId,
  PlayerStatKeys,
  PlayerTotalsAndAveragesReturnType,
  SoccerGameLeaderboardTypes,
  SoccerGameLeaderboardTypesCamelCase,
  TeamId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import {
  getDefaultEndDateForTrends,
  getDefaultGameTypesForTrends,
  getDefaultStartDateForTrends,
  ObjectKeys,
  ObjectValues
} from "../../utils";
import moment from "moment";
import { getPlayerSoccerStatDefs } from "../../soccer-logic";
import { PlayerSoccerStatComputations } from "../../soccer-logic/SoccerStatComputations/PlayerSoccerStatComputations";
import { isDevelopmentEnv } from "../../internal-utils/misc";

export async function trends__server__getPlayerTotalsAndAverages(p: {
  teamIdsWithSquad?: string[];
  matchTypes?: (MATCH_TYPES.game | MATCH_TYPES.scrimmage)[];
  startDateMS?: number;
  endDateMS?: number;
  playerIds: PlayerId[];
  locale: string;
}): Promise<PlayerTotalsAndAveragesReturnType> {
  // SERVER_ONLY_TOGGLE

  const startDateMS = p.startDateMS ?? getDefaultStartDateForTrends();
  const endDateMS = p.endDateMS ?? getDefaultEndDateForTrends();
  const matchTypes = p.matchTypes && p.matchTypes.length ? p.matchTypes : getDefaultGameTypesForTrends();

  const allPlayerStatKeys: string[] = ObjectKeys(PlayerStatKeys).map(k => PlayerStatKeys[k]);

  const q1 = p.playerIds;
  const q2 = matchTypes;
  const q3 = startDateMS;
  const q4 = endDateMS;
  const queryParams = [q1, q2, q3, q4];
  if (p.teamIdsWithSquad?.length) {
    const q5 = p.teamIdsWithSquad;
    queryParams.push(q5);
  }

  const query = `select
  count(*) as num_games,
  sum(coalesce(cast(timespentatposition->>'goalkeeper' as numeric), 0)) as time_as_goalkeeper,
  sum(coalesce(cast(timespentatposition->>'defender' as numeric), 0)) as time_as_defender,
  sum(coalesce(cast(timespentatposition->>'midfielder' as numeric), 0)) as time_as_midfielder,
  sum(coalesce(cast(timespentatposition->>'forward' as numeric), 0)) as time_as_forward,
  ${allPlayerStatKeys
    .map(statKey => {
      return `sum(coalesce(cast(a.player_stats ->> '${statKey}' as numeric), 0)) as stat_total_${statKey}, avg(cast(a.player_stats ->> '${statKey}' as numeric)) as stat_avg_${statKey}`;
    })
    .join(",")},
  sum(coalesce(cast(dsp.award_mvp_nodes -> 'mvpWinners' ->> 'mvp' as numeric), 0))             as mvp_overall,
  sum(coalesce(cast(dsp.award_mvp_nodes -> 'mvpWinners' ->> 'bestOffense' as numeric), 0))             as mvp_offense,
  sum(coalesce(cast(dsp.award_mvp_nodes -> 'mvpWinners' ->> 'bestDefense' as numeric), 0))             as mvp_defense,
  -- award example
  ${ObjectValues(SoccerGameLeaderboardTypes)
    .map(leaderboardType => {
      return `sum(coalesce(cast(dsp.award_mvp_nodes -> 'leaderboardWinners' ->> '${leaderboardType}' as numeric), 0)) as award_${SoccerGameLeaderboardTypesCamelCase[leaderboardType]}`;
    })
    .join(",")}
from (
    select dsn.*
    from mirror_calendarentry c,
         derived_snapshot_player_shard dsn
    where c.id = dsn.calendar_entry_id
      ${p.teamIdsWithSquad?.length ? `and c.item ->> 'teamIdWithSquad' = any ($5::text[])` : ""}
      and c.item ->> 'isExcludedFromAggregateStats' is null
      and c.item ->> 'calendarEntryType' = any ($2::text[])
      and c.c_start_date_truncated_ms between $3 and $4
      and player_id = any ($1::text[])
) a
    left join derived_soccergame_awards_mvp_by_player_shards dsp on a.calendar_entry_id = dsp.calendar_entry_id
and a.player_id = dsp.player_id;`;

  let tempResult: PlayerTotalsAndAveragesReturnType = {
    avg: {},
    total: {},
    awards: {
      assists: 0,
      crosses: 0,
      fouls: 0,
      goalieDistribution: 0,
      goalieSaves: 0,
      passEfficiency: 0,
      possessionParticipation: 0,
      possessionSuccess: 0,
      recoveries: 0,
      shotEfficiency: 0,
      subImpact: 0,
      touches: 0
    },
    mvps: { bestDefense: 0, bestOffense: 0, mvp: 0 },
    numGames: 0
  };

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  if (r1.rowCount > 0) {
    const r = r1.rows[0];
    let total: Record<string, number> = {};
    let avg: Record<string, number> = {};
    allPlayerStatKeys.forEach(statKey => {
      const playerStatKey = statKey as PlayerStatKeys;

      if (getPlayerSoccerStatDefs(p.locale)[playerStatKey].stringFormatHint === "percent") {
        total[statKey] = parseFloat(r[`stat_total_${statKey}`]);
        avg[statKey] = parseFloat(r[`stat_avg_${statKey}`]);
      } else {
        total[statKey] = parseInt(r[`stat_total_${statKey}`]);
        avg[statKey] = parseFloat(r[`stat_avg_${statKey}`]);
      }
    });
    tempResult = {
      numGames: parseInt(r.num_games),
      mvps: {
        bestDefense: parseInt(r.mvp_defense),
        bestOffense: parseInt(r.mvp_offense),
        mvp: parseInt(r.mvp_overall)
      },
      awards: ObjectKeys(SoccerGameLeaderboardTypesCamelCase).reduce((acc, leaderBoardType) => {
        acc[leaderBoardType] = parseInt(r[`award_${SoccerGameLeaderboardTypesCamelCase[leaderBoardType]}`]);
        return acc;
      }, {} as { [type in SoccerGameLeaderboardTypes]: number }),
      avg,
      total
    };
  }

  const returnVal = { ...tempResult };

  // Compute the percentage stats

  allPlayerStatKeys.forEach(statKey => {
    const playerStatKey = statKey as PlayerStatKeys;

    if (getPlayerSoccerStatDefs(p.locale)[playerStatKey].formatCategory === "percent") {
      const statComputationObject = PlayerSoccerStatComputations[playerStatKey];
      if (
        statComputationObject.statType === "player" &&
        getPlayerSoccerStatDefs(p.locale)[playerStatKey].formatCategory === "percent"
      ) {
        if (!!statComputationObject.computeSeasonAverage) {
          const avg = statComputationObject.computeSeasonAverage({
            playerTotalsAndAverages: tempResult
          });
          if (avg) {
            returnVal.avg[statKey as PlayerStatKeys] = avg;
            returnVal.total[statKey as PlayerStatKeys] = avg;
          }
        } else {
          if (isDevelopmentEnv()) {
            console.warn(`Player Stat of precentage type is missing computeSeasonAverage function: ${playerStatKey}`);
          }
        }
      }
    }
  });

  return returnVal;
  // SERVER_ONLY_TOGGLE
}

trends__server__getPlayerTotalsAndAverages.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
